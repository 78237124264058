@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap);
.primary-btn {
    width: 100%;
    min-height: 64px;
    background-color: var(--color-primary) !important;
    border-radius: 6px !important;

    /* font-family: 'SF Pro Display'; */
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 1.3rem !important;
    text-transform: uppercase;
}

.small-btn {
    background: var(--color-primary) !important;
    border-radius: 4px !important;
    min-width: 92px;
    min-height: 40px;
    color: #F7FAFC !important;
    font-size: 1.63rem;
    font-weight: normal;
}

.text-btn {
    color: var(--color-primary) !important;
    font-size: 1.6rem;
}

.text-btnOK {
    color: var(--color-primary) !important;
    font-size: 1.6rem;
    background: none !important;
    border-color: var(--color-primary) !important;
}

.text-btnOK:hover {
    color: none !important;
    border-color: var(--color-primary) !important;

}

.ant-btn:hover,
.ant-btn:focus {
    color: var(--color-primary) !important;
    background: none !important;
    border-color: var(--color-primary) !important;
}

.dropdown-btn {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    background: #dadada !important;
    border-radius: 7px !important;
    min-height: 40px;
    color: var(--color-primary) !important;
    font-size: 1.1rem;
}

.dropdown-btn.ant-btn:hover {
    border-color: var(--color-primary) !important;
    background: #dadada !important;
}

/* //CSV Reader Button Styling */
/* Custom styles for CSVReader button */
.csv-reader-button input[type="file"] {
    position: relative;
    visibility: hidden;
}

.custom-csv-label {
    color: #333;
    font-size: 1.2rem;

}

.custom-csv-label:hover {
    color: #D8232A;

}

.uploadIcon {
    margin-right: 1rem;
    font-size: 1.2rem;

}

.csv-reader-button label {
    visibility: hidden;
}

.csvDiv {
    display: 'inline-flex';
    align-items: 'center';
    justify-content: 'center';
}
.ant-drawer-content-wrapper {
    border-radius: 20px 0px 0px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-drawer .ant-drawer-content {
    background-color: #F3F3F3;
    border-radius: 20px 0px 0px 20px;
    z-index: 10 !important;
}

.custom-drawer .ant-drawer-header {
    padding: 42px 54px;
    background-color: inherit;
    border-bottom: 1px solid var(--color-primary);
}

.custom-drawer .ant-drawer-title {
    font-weight: 600;
    font-size: 2.5rem;
    color: var(--color-primary);
}

.custom-form-submit {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;

}

.custom-form-submit .small-btn {
    width: 290px;
}

.custom-form label {
    font-weight: 600;
    font-size: 2.0rem !important;
}

.custom-form .ant-input,
.custom-form .ant-picker,
.custom-form .ant-input-number {
    background: none;
    border: none;
    border-bottom: 1.25px solid #ACACAC;
    border-radius: 0px !important;
    font-size: 1.8rem;
    padding-left: 0px;
    width: 100%;
}

.custom-form .ant-select-selector {
    background: none !important;
    border: none !important;
    border-bottom: 1.25px solid #ACACAC !important;
    border-radius: 0px !important;
}

/* Banner */
.ant-card.banner-card {
    border-radius: 8px;
    -webkit-filter: drop-shadow(2px 4px 4px #ACACAC);
            filter: drop-shadow(2px 4px 4px #ACACAC);
}

.banner-row {
    width: 100%;
    padding: 16px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.img-card-banner {
    width: 100%;
    display: block;
}

.banner-card-expired .img-card-banner {
    -webkit-filter: grayscale();
            filter: grayscale();
}

/*
 * IMAGE MAP
 */

.image-map-drawer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#image-map {
    height: 95%;
    width: 100%;
}

.table-content-slider-image {
    max-width: 100px; 
    max-height: 200px;
    height: auto;
    display: block;
    margin: 0 auto; 
}

.custom-table.masterstock-table .ant-table-thead>tr>th,
.custom-table.contractpricing-table .ant-table-thead>tr>th {
    font-size: 1.4rem !important;
}


.custom-table.masterstock-table .ant-table-tbody>tr>td,
.custom-table.contractpricing-table .ant-table-thead>tr>th {
    font-size: 1.2rem !important;
}

.contract-pricing-search {
    margin-bottom: 24px;
}

/* parcel labels */

.parcel-label-container {
    width: 381.73px;
    padding: 4px 8px;
    border: 0.1px solid #000;
    color: #000 !important
}

.parcel-label-container {
    font-size: 0.5rem;
}

.parcel-label-header {
    font-size: 10px;
    font-weight: 900;
}

.parcel-label-header-hidden {
    visibility: hidden;
}

.parcel-label-barcode {
    display: flex;
    justify-content:center;
    border: none !important;
}

.parcel-label-col {
    border: 0.1px solid #000;
    width: 100%;
    padding: 8px;
    font-size: 10px;
    font-weight: 900;
}

.label-barcode {
    width: 200px;
}

.label-barcode-container {
    display: flex;
    flex-direction: column;
}

.label-barcode-info {
    font-size: 8px;
}

.copy-btn{
    background: none !important;
}

.measurement-units {
    margin: 15px 0;
  }
  
  .measurement-units h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }
  
  .unit-labels {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
  }
  
  .unit-labels span {
    font-size: 12px;
    color: #666;
    background-color: #f5f5f5;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }
.custom-files-upload .ant-upload-list {
    display: flex;
    overflow-y: scroll;
}

.custom-files-upload .ant-upload-select-picture-card,
.custom-files-upload .ant-upload-list-picture-card-container {
    display: block !important;
    border: 1.2028px dashed #181725 !important;
    border-radius: 6px !important;
    background: none !important;
    min-width: 104px !important;
}

.custom-files-upload  .ant-upload-list-picture-card-container {
    margin-right: 28px;
}

.custom-files-upload  .ant-upload-list-item-name {
    font-size: 1.0rem;
    color: #797979;
}

.pdf-icon-render {
    font-size: 16px;
}
.form-delete-container,
.form-options-container {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
}

h2.form-header {
    font-weight: 600;
    font-size: 2.4rem;
    color: #4C5862;
}

h3.form-header {
    font-weight: 600;
    font-size: 1.8rem;
    color: #181725;
}

/* inventory-form-image-main */
.inventory-form-image-main {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 135px;
    padding: 8px;
}

.inventory-form-image-main img {
    object-fit: contain;
    max-height: 100% !important;
    max-width: 100% !important;
}

.inventory-form-image-list {
    display: flex;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    overflow-y: scroll;
}

img.inventory-form-image-small-img {
    padding: 0px 8px;
    max-height: 30px;
    height: auto;
    width: auto;
    border: 1px solid #000000;
    border-radius: 5px;
}

.inventory-form-image-small {
    display: flex;
    justify-content: center;
    align-items:center;
    box-sizing: border-box;
    height: 40px;
    cursor: pointer;
}

.inventory-form-image-add {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    color: #718096;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.inventory-form-image-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #718096;
    font-weight: 600;
    font-size: 14px;
    margin-top: 16px;
}

.inventory-form-image-next {
    cursor: pointer;
    min-width: 28px;
    min-height: 5px;
    border: 0.7px solid #718096;
    border-radius: 33.6px;
    margin-left: 5px;
}

.inventory-form-image-addbtn.ant-btn {
    font-weight: 600;
    font-size: 0.9rem;
    color: #718096;
    background: none !important;
    border: none;
    box-shadow: none;
}

/* row */
.inventory-form .ant-col {
    padding: 8px;
}

.inventory-form-small-header {
    font-weight: 600;
    font-size: 1.2rem;
}

.inventory-form-fullDescription {
    font-size: 1.8rem;
    color: #4C5862;
}

.inventory-form-document-list {
    margin-top: 25px;
}

.inventory-form-category-add {
    margin-left: 16px;
}

/* order status */
.order-status-getlabels {
    display: flex;
    justify-content:flex-end;
}

.confirmBtn {
    background-color:var(--color-primary) !important;
    border: none !important;
}

/*lexical editor*/
.editor-container {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 16px;
}

.editor-placeholder {
  position: absolute;
  top: 100px; 
  padding-left: 1em;
  left: 16px !important; 
  -webkit-user-select: none; 
      -ms-user-select: none; 
          user-select: none;
  pointer-events: none;
}

.con-lex {
    position: relative;
    overflow: visible !important;
    z-index: 2000 !important; 
}


.con-lex > .editor-shell {
    margin: inherit;
}

.editor-shell {
    max-width: inherit !important;
}

/* Code editor text area */
.code-editor {
    width: 100%;
    height: 300px;
    font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 10px;
    background-color: #1e1e1e;
    color: #d4d4d4;
    border: 1px solid #333;
    border-radius: 4px;
    resize: vertical;
    overflow-y: auto;
    tab-size: 2;
  }
  .textBtn {
    align-content: flex-start;
  }


  .survey-response-drawer {
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
}

.survey-response-title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: bold;
  color: #1890ff;
}

.survey-response-card {
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 16px;
}

.survey-response-question {
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.survey-response-answer {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 8px;
  min-height: 60px;
  background-color: #f5f5f5;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  width: 100%;
}

.survey-response-submitted {
  font-style: italic;
  color: #888;
  text-align: right;
  margin-top: 8px;
}
#navbar-main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.5em;
}

.navbar-main-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 29px;
}

.navbar-collapsed-menu-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.navbar-collapsed-menu {
    font-size: 2.0rem;
}

.navbar-main-details-name {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: right;
}

.navbar-main-details-role {
    font-size: 1.1rem;
    color: var(--color-primary);
    float: right;
    text-transform: uppercase;
}

/* notification */
.notification-container {
    width: 350px;
    height: 150px;
    overflow-y: scroll;
    background-color: #fff;
}

.notification-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.5em;
}

.notification {
    padding: 8px;
    margin-bottom: 4px;
    cursor: pointer;
    background-color: var(--color-background);
}
/*  */
.menu-icon {
    font-size: 2.0rem !important;
    align-self: center;
}

/*  */
.sidermain {
    background-color: #fff !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.sidermain-menu-link {
    font-size: 1.5rem;
    font-weight: 600;
}

.sidermain-logo-container {
    margin-top: 43px;
    margin-bottom: 90px;
    display: flex;
    justify-content: center;
}

.sidermain-logo {
    width: 160px;
}

.sidermain-logo-collapsed {
    width: 80%;
}

.sidermain-logout {
    position: absolute !important;
    bottom: 2% !important;
    padding-bottom: 16px;
    align-items: center;
    background-color: var(--color-primary-light) !important;
}

.sidermain-logouthover {
    background-color: white !important;
}

/* active menu */
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid var(--color-primary) !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    background: none !important;
}

.ant-menu-item-active * {
    color: var(--color-background) !important
}

.ant-menu-item-active .menu-icon svg {
    stroke: var(--color-background) !important
}

.ant-menu-item-selected * {
    color: var(--color-primary) !important
}

.ant-menu-item-selected .menu-icon svg {
    stroke: var(--color-primary) !important
}

.ant-menu-item-active.ant-menu-item-selected:hover {
    color: var(--color-primary) !important
}

.ant-menu-item-active.ant-menu-item-selected:hover .menu-icon svg {
    stroke: var(--color-primary) !important;
}

.menu-icon svg {
    stroke: #000;
}



.pageheader-options-categories {
    margin-right: 24px !important;
}

.long-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

img.product-image {
    display: block;
    max-width: 30px;
    max-height: 30px;
    width: auto;
    height: auto;
}

.skuPanelDrawer{
    z-index: 10 !important;
}

.create-flex {
    display: "flex";
    flex-direction: 'row';
    margin-bottom: 16px;
}

.parcels-input {
    width: 265px !important;
    background: none;
    border: 1.25px solid #ACACAC;;
    border-radius: 0px !important;
    font-size: 1.2rem;
    padding: 6px;
    margin-right: 8px;
}

.order-tab-heading {
    font-size: 15px;
    font-weight: bold;
}

.order-spacing {
    margin-top: 20px;
}

.order-tab-comments {
    font: 12px;
    font-weight: normal;
    color: #7C7C7C;   
}

.product-border {
    display: flex;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 35px;
    padding-top: 13px;
}

.order-product-image {
    width: 100px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
    margin-right: 16px;
}

.image-style {
    max-width: 100px;
    max-height: 80px;
    /* margin-top: 25px; */
}

.product-quantity-font {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
}

.product-font {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center
}

.product-text-margin {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.coloumn-flex {
    display: flex;
    flex-direction: column;
}

.heading1 {
    font-weight: 600;
    font-size: 13px;
}

.heading2 {
    font-style: 14px;
    color: #7C7C7C;
}

.heading3 {
    font-weight: 600;
    font-size: 18px;
}

.order-product-info {
    display: flex;
    justify-content: space-between;
}

.long-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

img.product-image {
    display: block;
    max-width: 30px;
    max-height: 30px;
    width: auto;
    height: auto;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.message-box {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: #2F80ED;
    border-radius: 15.6308px;
    margin-top: 16px;
    margin-left: 32px;
    width: 430px;
}

.message-box-font {
    font-weight: 600;
    font-size: 20.4462px;
    padding-bottom: 10px
}

.message-box-time {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.flex-only {
    display: flex;
}

.message-box-sender {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15.6308px;
    background: #FFFFFF;
    border-radius: 15.6308px;
    margin-top: 30px;
    margin-left: 32px;
    width: 380px;
    height: 75px;
}

.message-image {
    width: 82px;
    height: 84px;
    border-radius: 52.7538px;
    display: flex;
    justify-content: flex-end
}

.message-bottom-margin {
    width: 100%;
    background: #FFFFFF;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.message-bottom-style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
}

.icon-style-right {
    cursor: pointer;
}

.pageheader-options .orders-date-picker {
    margin-right: 16px;
    border-radius: 4px !important;
    height: 40px;
    width: 240px;
    border: 1px solid #E2E8F0 !important;
}

.pageheader-options .orders-date-picker * {
    color: #CBD5E0 !important;
}

.pageheader-options-search.orders-search {
    margin-right: 0px;
    width: auto;
}

.orders-drawer-header-sutitle {
    color: #000;
    font-size: 1.2rem;
    margin-top: 16px;
}

.order-statustab-head {
    margin-bottom: 16px;
}

.order-messages-list {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column-reverse;
}

.create-flex {
    display: "flex";
    flex-direction: 'row';
    margin-bottom: 16px;
}

.parcels-input {
    width: 265px !important;
    background: none;
    border: 1.25px solid #ACACAC;;
    border-radius: 0px !important;
    font-size: 1.2rem;
    padding: 6px;
    margin-right: 8px;
}

.order-tab-heading {
    font-size: 15px;
    font-weight: bold;
}

.order-spacing {
    margin-top: 20px;
}

.order-tab-comments {
    font: 12px;
    font-weight: normal;
    color: #7C7C7C;   
}

.product-border {
    display: flex;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 35px;
    padding-top: 13px;
}

.order-product-image {
    width: 100px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
    margin-right: 16px;
}

.image-style {
    max-width: 100px;
    max-height: 80px;
    /* margin-top: 25px; */
}

.product-quantity-font {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
}

.product-font {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center
}

.product-text-margin {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.coloumn-flex {
    display: flex;
    flex-direction: column;
}

.heading1 {
    font-weight: 600;
    font-size: 13px;
}

.heading2 {
    font-style: 14px;
    color: #7C7C7C;
}

.heading3 {
    font-weight: 600;
    font-size: 18px;
}

.order-product-info {
    display: flex;
    justify-content: space-between;
}

.long-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

img.product-image {
    display: block;
    max-width: 30px;
    max-height: 30px;
    width: auto;
    height: auto;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.message-box {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: #2F80ED;
    border-radius: 15.6308px;
    margin-top: 16px;
    margin-left: 32px;
    width: 430px;
}

.message-box-font {
    font-weight: 600;
    font-size: 20.4462px;
    padding-bottom: 10px
}

.message-box-time {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.flex-only {
    display: flex;
}

.message-box-sender {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15.6308px;
    background: #FFFFFF;
    border-radius: 15.6308px;
    margin-top: 30px;
    margin-left: 32px;
    width: 380px;
    height: 75px;
}

.message-image {
    width: 82px;
    height: 84px;
    border-radius: 52.7538px;
    display: flex;
    justify-content: flex-end
}

.message-bottom-margin {
    width: 100%;
    background: #FFFFFF;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.message-bottom-style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
}

.icon-style-right {
    cursor: pointer;
}

.pageheader-options .orders-date-picker {
    margin-right: 16px;
    border-radius: 4px !important;
    height: 40px;
    width: 240px;
    border: 1px solid #E2E8F0 !important;
}

.pageheader-options .orders-date-picker * {
    color: #CBD5E0 !important;
}

.pageheader-options-search.orders-search {
    margin-right: 0px;
    width: auto;
}

.orders-drawer-header-sutitle {
    color: #000;
    font-size: 1.2rem;
    margin-top: 16px;
}

.order-statustab-head {
    margin-bottom: 16px;
}

.order-messages-list {
    padding-bottom: 50px;
}

#survey-root {
    background-color: var(--color-background);
    /* width: 100vw !important;*/
    min-height: 90vh !important;
    display: flex;
}


.question-type-list{
  display: flex;
  flex-direction: column;
  border-right: 1px solid #afafaf;
  margin-right: 40px;
  width: 20%;
  align-items: center;
}

.add-question-text{
  color: var(--color-primary);
  margin-top: 40px;
  margin-bottom: 25px;

}
.q-type-contain{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.question-type-box{
  width: 66px;
  height: 66px;
  border-radius: 10px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 ;
}


.type-name{
  font-size: 1rem;
}


.cb-question .ant-checkbox-group-item  {
  border-radius: 12px !important;
  background-color: #1D394862 !important;
  padding: 8px !important;
  color: #fff !important;
  margin: 4px;
}

.survey-droppable-area{
  display: flex;
  flex-direction: column;
}



.add-question-text{
  color: var(--color-primary);
  margin-top: 40px;
  margin-bottom: 25px;
  
}

.question-title{
  padding-left: 1em;
  max-width: 80%;
}

.question-type-box{
  width: 66px;
  height: 66px;
  border-radius: 10px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 ;
}

.cb-question .ant-checkbox-group-item  {
  border-radius: 12px !important;
  background-color: #1D394862 !important;
  padding: 8px !important;
  color: #fff !important;
  margin: 4px;
}

.q-type-contain{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.type-name{
  font-size: 1rem;
}

.survey-section{
  background-color: var(--color-background);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey-droppable-area{
  width: 95%;
  min-height: 70%;
  overflow-y: auto;
}

.survey-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.survey-select{
  width: 100% !important;
  margin: 50px 0px  !important;
    border: 0.5px solid black !important;
  background-color: transparent !important;
}

.survey-select  .ant-select-selector{
  background-color:gray solid !important;
  border: none !important;
}


.question{
  display: flex;
  flex-direction: column;
  border-bottom: gray solid 1px;
  padding: 30px 0px;
}

.question:hover{
  background-color: #fafafa;
}

.items-head{
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-opt{
  margin-right: 10px;
}

.add-item-btn{
  background-color: var(--color-primary) !important;
  color:  #fff !important;
  height: 25px !important;
  width: 25px !important;
  font-size: 0.8rem;
  border: none !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  border: none !important;
  border-radius: 50% !important;
  margin-left: 10px;
}

.question-top{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  color: var(--color-primary);
}

.element{
  margin-left: 1em !important;
}

.question-controls{
  color: #1E7D79;
}

.q-rate .ant-rate-star{
  font-size: 4rem !important;
  color: var(--color-primary) !important;
}

.edit-q{
  margin-right: 5px;
  color: var(--color-primary) !important;
  border: none !important;
}

.delete-q{
  color: var(--color-primary) !important;
  margin-right: 5px;
  border: none !important;
}

.ant-radio-inner::after{
  background-color: var(--color-primary) !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--color-primary) !important;
}

.ant-radio:hover .ant-radio-inner{
  border-color: var(--color-primary) !important;
}

.multi-choice{
  display: flex !important;
  flex-direction: column;
  margin-top: -20px !important;
}

.new-survey-btn{
  background-color: var(--color-primary) !important;
  color: #fff !important;
  border-color: #fff !important;
}

.new-survey-btn:hover{
  background-color: #1D3948c7 !important;
}

.welcome-closing-messsage{
  padding-left: 0.5em;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
}

.ant-checkbox-inner{
  border: 1px solid #d9d9d9 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}

.opening-msg{
  font-weight: bold;
  padding-left: 0.5em;
  font-size: 1.8rem;
  color: var(--color-primary);
}

.self-rev-cb{
  margin: 10px 0px !important;
}




.survey-root {
    display: flex;
    padding: 20px;
    background-color: #f0f2f5;
}

.question-type-list {
    width: 200px;
    margin-right: 20px;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.survey-section {
    flex: 1 1;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.question {
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
}

.question-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.question-title {
    font-weight: bold;
}

.question-element {
    margin-top: 10px;
}

.welcome-closing-messsage {
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.optional-fields-dropdown {
  margin-left: auto;
  width: 200px; /* Adjust as needed */
}
.survey{
  width: 90%;
}


.order-retry-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
}

.order-retry-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.order-retry-summary {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
}

.order-retry-items {
    width: 100%;
    max-width: 800px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.order-info-table {
    width: 100%;
    border-radius: 8px;
    background-color: #f7f9fc;
}

.order-retry-button {
    text-align: center;
    margin-top: 20px;
}

.order-retry-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
}


#landing {
    display: flex;
    width: 100%;
    min-height: 100vh;
    background: #fff
}

.landing-image-container {
    flex: 1 1 auto;
    background-image: url(/static/media/working-man.f9c733ec.png);
    background-size: cover;
}

.landing-login-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 80px;
}

.landing-login-applogo {
    width: 400px;
}

.landing-login-form {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    max-width: 638px;
    padding: 105px;
}

.landing-login-form>* {
    margin-bottom: 15px !important;
}

.landing-login-form .text-input {
    height: 56px;
    border: 1px solid var(--color-primary) !important;
    border-radius: 6px !important;
    color: var(--color-primary) !important;
    font-size: 1.4rem;
    font-weight: 600;
}

.landing-login-form .text-input::-webkit-input-placeholder, .text-input>input::-webkit-input-placeholder {
    color: #D8232A80 !important;
    font-size: 1.4rem;
    font-weight: 600;
}

.landing-login-form .text-input:-ms-input-placeholder, .text-input>input:-ms-input-placeholder {
    color: #D8232A80 !important;
    font-size: 1.4rem;
    font-weight: 600;
}

.landing-login-form .text-input::placeholder,
.text-input>input::placeholder {
    color: #D8232A80 !important;
    font-size: 1.4rem;
    font-weight: 600;
}

.text-input>input {
    color: var(--color-primary) !important;
    font-size: 1.4rem;
    font-weight: 600;
}

.forgot-password-btn {
    font-size: 1.2rem;
    cursor: pointer;
    color: var(--color-primary);
}
#account-verification {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.account-verification-applogo {
    width: 250px;
}

.account-verification-header {
    margin-top: 16px;
    margin-bottom: 24px;
}

.account-verification-input {
    width: 426px !important;
}

.decline-input{
    margin-top: 0.5em !important;
}

.account-verification-input{
    margin-top: 1em;
}
#account-verification {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.account-verification-applogo {
    width: 250px;
}

.account-verification-header {
    margin-top: 16px;
    margin-bottom: 24px;
}

h2.account-verification-header {
    margin-top: 16px;
    margin-bottom: 24px;
}

.account-verification-input {
    width: 426px !important;
}
#forgot-verification {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pdf-viewer-container {
    width: 100vw;
    height: 100vh;
}

.pdf-viewer-iframe {
    width: 100%;
    height: 100vh;
}
:root {
    --color-background: #F7F7F7;
    /* --color-primary-lighter: #d7c499; */
    /* --color-primary-light: #f9f8f2; */
    --color-primary: #D8232A;
    /* --color-primary-dark: #12284d83; */
    --color-secondary: #000000;
    /* --color-secondary-dark: #12284d; */
    /* --color-title-in-primary: #ffffff; */
    --color-text-in-primary: #000;
    /* --color-text-title: #32264d; */
    /* --color-text-complement: #9c98a6; */
    /* --color-text-base: #6a6180; */
    /* --color-text-brown: #59394a; */
    /* --color-line-in-white: #e6e6f0; */
    /* --color-input-background: #f8f8fc; */
    /* --color-button-text: #ffffff; */
    /* --color-box-base: #0a0101; */
    /* --color-box-footer: #fafafc; */

    font-size: 62.5%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    min-height: 100vh;
    font-family: 'Source Sans Pro', sans-serif;
    background-color: #F7F7F7;
    background-color: var(--color-background);
}

body {
    color: #000;
    color: var(--color-text-in-primary);
}

.home-content {
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.home-content-page {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
}

/*
    HEADERS
*/
h1.header {
    font-weight: bold;
    font-size: 2.0rem;
    color: #4A5568;
}

/*
    TABLES
*/
.custom-table .ant-table-tbody>tr>td {
    color: #718096 !important;
    font-size: 1.6rem !important;
}

.custom-table .ant-table-thead>tr>th {
    background: #EDF2F7;
    font-weight: 600;
    font-size: 1.6rem;
    color: #4A5568;
    min-height: 16px;
}

.expanded-table-inventory {
    max-height: 45vh !important;
    overflow-y: scroll !important;
}

.custom-table .expanded-table .ant-table-thead>tr>th {
    background: #EDF2F7;
    font-size: 1.4rem;
}

.custom-table .expanded-table .ant-table-tbody>tr>td {
    font-size: 1.2rem !important;
}

/* remove header divider */
.custom-table .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

/* header padding */
.custom-table .ant-table-thead>tr>th {
    padding: 8px 16px !important;
}

/* page heaer */
.pageheader-container {
    display: flex;
    justify-content: space-between;
    padding: 1.5em;
}

.pageheader-options {
    display: flex;
}

.pageheader-options > * {
    padding-right: 24px;
}

.pageheader-seperator {
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid #E2E2E2;
}

.pageheader-options-search {
    width: '240px';
    margin-right: 24px;
    height: 40px;
    border: 1px solid #E2E8F0 !important;
    border-radius: 4px !important;
}

.pageheader-options-search>input::-webkit-input-placeholder {
    color: #CBD5E0 !important;
    font-size: 1.6rem;
    font-weight: 400;
}

.pageheader-options-search>input:-ms-input-placeholder {
    color: #CBD5E0 !important;
    font-size: 1.6rem;
    font-weight: 400;
}

.pageheader-options-search>input::placeholder {
    color: #CBD5E0 !important;
    font-size: 1.6rem;
    font-weight: 400;
}

/* custom able */
.custom-table-container {
    overflow-y: scroll;
}

.custom-table-address-field {
    font-size: 1.2rem;
}

/*
    SPIN LOADER
*/
.custom-spin {
    color: #000 !important;
    font-weight: 600;
}

/* tabs */
.custom-tabs .ant-tabs-tab:hover {
    color: #F7F7F7;
    color: var(--color-background);

}
.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #D8232A;
    color: var(--color-primary);
}

.custom-tabs .ant-tabs-ink-bar {
    background: #D8232A;
    background: var(--color-primary);
}

/*
    messages
*/
.message-notification {
    padding: 24px;;
}

.toolbar{
    height: 50px;
}

.dropdown {
    z-index: 1001 !important;
}

.Modal__overlay{
    z-index: 1001 !important;
}

