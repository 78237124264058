@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');


:root {
    --color-background: #F7F7F7;
    /* --color-primary-lighter: #d7c499; */
    /* --color-primary-light: #f9f8f2; */
    --color-primary: #D8232A;
    /* --color-primary-dark: #12284d83; */
    --color-secondary: #000000;
    /* --color-secondary-dark: #12284d; */
    /* --color-title-in-primary: #ffffff; */
    --color-text-in-primary: #000;
    /* --color-text-title: #32264d; */
    /* --color-text-complement: #9c98a6; */
    /* --color-text-base: #6a6180; */
    /* --color-text-brown: #59394a; */
    /* --color-line-in-white: #e6e6f0; */
    /* --color-input-background: #f8f8fc; */
    /* --color-button-text: #ffffff; */
    /* --color-box-base: #0a0101; */
    /* --color-box-footer: #fafafc; */

    font-size: 62.5%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    min-height: 100vh;
    font-family: 'Source Sans Pro', sans-serif;
    background-color: var(--color-background);
}

body {
    color: var(--color-text-in-primary);
}

.home-content {
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.home-content-page {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
}

/*
    HEADERS
*/
h1.header {
    font-weight: bold;
    font-size: 2.0rem;
    color: #4A5568;
}

/*
    TABLES
*/
.custom-table .ant-table-tbody>tr>td {
    color: #718096 !important;
    font-size: 1.6rem !important;
}

.custom-table .ant-table-thead>tr>th {
    background: #EDF2F7;
    font-weight: 600;
    font-size: 1.6rem;
    color: #4A5568;
    min-height: 16px;
}

.expanded-table-inventory {
    max-height: 45vh !important;
    overflow-y: scroll !important;
}

.custom-table .expanded-table .ant-table-thead>tr>th {
    background: #EDF2F7;
    font-size: 1.4rem;
}

.custom-table .expanded-table .ant-table-tbody>tr>td {
    font-size: 1.2rem !important;
}

/* remove header divider */
.custom-table .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

/* header padding */
.custom-table .ant-table-thead>tr>th {
    padding: 8px 16px !important;
}

/* page heaer */
.pageheader-container {
    display: flex;
    justify-content: space-between;
    padding: 1.5em;
}

.pageheader-options {
    display: flex;
}

.pageheader-options > * {
    padding-right: 24px;
}

.pageheader-seperator {
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid #E2E2E2;
}

.pageheader-options-search {
    width: '240px';
    margin-right: 24px;
    height: 40px;
    border: 1px solid #E2E8F0 !important;
    border-radius: 4px !important;
}

.pageheader-options-search>input::placeholder {
    color: #CBD5E0 !important;
    font-size: 1.6rem;
    font-weight: 400;
}

/* custom able */
.custom-table-container {
    overflow-y: scroll;
}

.custom-table-address-field {
    font-size: 1.2rem;
}

/*
    SPIN LOADER
*/
.custom-spin {
    color: #000 !important;
    font-weight: 600;
}

/* tabs */
.custom-tabs .ant-tabs-tab:hover {
    color: var(--color-background);

}
.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-primary);
}

.custom-tabs .ant-tabs-ink-bar {
    background: var(--color-primary);
}

/*
    messages
*/
.message-notification {
    padding: 24px;;
}

.toolbar{
    height: 50px;
}

.dropdown {
    z-index: 1001 !important;
}

.Modal__overlay{
    z-index: 1001 !important;
}
