.create-flex {
    display: "flex";
    flex-direction: 'row';
    margin-bottom: 16px;
}

.parcels-input {
    width: 265px !important;
    background: none;
    border: 1.25px solid #ACACAC;;
    border-radius: 0px !important;
    font-size: 1.2rem;
    padding: 6px;
    margin-right: 8px;
}

.order-tab-heading {
    font-size: 15px;
    font-weight: bold;
}

.order-spacing {
    margin-top: 20px;
}

.order-tab-comments {
    font: 12px;
    font-weight: normal;
    color: #7C7C7C;   
}

.product-border {
    display: flex;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 35px;
    padding-top: 13px;
}

.order-product-image {
    width: 100px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
    margin-right: 16px;
}

.image-style {
    max-width: 100px;
    max-height: 80px;
    /* margin-top: 25px; */
}

.product-quantity-font {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
}

.product-font {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center
}

.product-text-margin {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.coloumn-flex {
    display: flex;
    flex-direction: column;
}

.heading1 {
    font-weight: 600;
    font-size: 13px;
}

.heading2 {
    font-style: 14px;
    color: #7C7C7C;
}

.heading3 {
    font-weight: 600;
    font-size: 18px;
}

.order-product-info {
    display: flex;
    justify-content: space-between;
}

.long-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

img.product-image {
    display: block;
    max-width: 30px;
    max-height: 30px;
    width: auto;
    height: auto;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.message-box {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: #2F80ED;
    border-radius: 15.6308px;
    margin-top: 16px;
    margin-left: 32px;
    width: 430px;
}

.message-box-font {
    font-weight: 600;
    font-size: 20.4462px;
    padding-bottom: 10px
}

.message-box-time {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.flex-only {
    display: flex;
}

.message-box-sender {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15.6308px;
    background: #FFFFFF;
    border-radius: 15.6308px;
    margin-top: 30px;
    margin-left: 32px;
    width: 380px;
    height: 75px;
}

.message-image {
    width: 82px;
    height: 84px;
    border-radius: 52.7538px;
    display: flex;
    justify-content: flex-end
}

.message-bottom-margin {
    width: 100%;
    background: #FFFFFF;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.message-bottom-style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
}

.icon-style-right {
    cursor: pointer;
}

.pageheader-options .orders-date-picker {
    margin-right: 16px;
    border-radius: 4px !important;
    height: 40px;
    width: 240px;
    border: 1px solid #E2E8F0 !important;
}

.pageheader-options .orders-date-picker * {
    color: #CBD5E0 !important;
}

.pageheader-options-search.orders-search {
    margin-right: 0px;
    width: auto;
}

.orders-drawer-header-sutitle {
    color: #000;
    font-size: 1.2rem;
    margin-top: 16px;
}

.order-statustab-head {
    margin-bottom: 16px;
}

.order-messages-list {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column-reverse;
}
