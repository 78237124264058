#landing {
    display: flex;
    width: 100%;
    min-height: 100vh;
    background: #fff
}

.landing-image-container {
    flex: 1 1 auto;
    background-image: url('../../assets/branding//working-man.png');
    background-size: cover;
}

.landing-login-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 80px;
}

.landing-login-applogo {
    width: 400px;
}

.landing-login-form {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    max-width: 638px;
    padding: 105px;
}

.landing-login-form>* {
    margin-bottom: 15px !important;
}

.landing-login-form .text-input {
    height: 56px;
    border: 1px solid var(--color-primary) !important;
    border-radius: 6px !important;
    color: var(--color-primary) !important;
    font-size: 1.4rem;
    font-weight: 600;
}

.landing-login-form .text-input::placeholder,
.text-input>input::placeholder {
    color: #D8232A80 !important;
    font-size: 1.4rem;
    font-weight: 600;
}

.text-input>input {
    color: var(--color-primary) !important;
    font-size: 1.4rem;
    font-weight: 600;
}

.forgot-password-btn {
    font-size: 1.2rem;
    cursor: pointer;
    color: var(--color-primary);
}