#navbar-main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.5em;
}

.navbar-main-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 29px;
}

.navbar-collapsed-menu-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.navbar-collapsed-menu {
    font-size: 2.0rem;
}

.navbar-main-details-name {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: right;
}

.navbar-main-details-role {
    font-size: 1.1rem;
    color: var(--color-primary);
    float: right;
    text-transform: uppercase;
}

/* notification */
.notification-container {
    width: 350px;
    height: 150px;
    overflow-y: scroll;
    background-color: #fff;
}

.notification-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.5em;
}

.notification {
    padding: 8px;
    margin-bottom: 4px;
    cursor: pointer;
    background-color: var(--color-background);
}