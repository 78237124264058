.form-delete-container,
.form-options-container {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
}

h2.form-header {
    font-weight: 600;
    font-size: 2.4rem;
    color: #4C5862;
}

h3.form-header {
    font-weight: 600;
    font-size: 1.8rem;
    color: #181725;
}

/* inventory-form-image-main */
.inventory-form-image-main {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 135px;
    padding: 8px;
}

.inventory-form-image-main img {
    object-fit: contain;
    max-height: 100% !important;
    max-width: 100% !important;
}

.inventory-form-image-list {
    display: flex;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    overflow-y: scroll;
}

img.inventory-form-image-small-img {
    padding: 0px 8px;
    max-height: 30px;
    height: auto;
    width: auto;
    border: 1px solid #000000;
    border-radius: 5px;
}

.inventory-form-image-small {
    display: flex;
    justify-content: center;
    align-items:center;
    box-sizing: border-box;
    height: 40px;
    cursor: pointer;
}

.inventory-form-image-add {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    color: #718096;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.inventory-form-image-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #718096;
    font-weight: 600;
    font-size: 14px;
    margin-top: 16px;
}

.inventory-form-image-next {
    cursor: pointer;
    min-width: 28px;
    min-height: 5px;
    border: 0.7px solid #718096;
    border-radius: 33.6px;
    margin-left: 5px;
}

.inventory-form-image-addbtn.ant-btn {
    font-weight: 600;
    font-size: 0.9rem;
    color: #718096;
    background: none !important;
    border: none;
    box-shadow: none;
}

/* row */
.inventory-form .ant-col {
    padding: 8px;
}

.inventory-form-small-header {
    font-weight: 600;
    font-size: 1.2rem;
}

.inventory-form-fullDescription {
    font-size: 1.8rem;
    color: #4C5862;
}

.inventory-form-document-list {
    margin-top: 25px;
}

.inventory-form-category-add {
    margin-left: 16px;
}

/* order status */
.order-status-getlabels {
    display: flex;
    justify-content:flex-end;
}

.confirmBtn {
    background-color:var(--color-primary) !important;
    border: none !important;
}

/*lexical editor*/
.editor-container {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 16px;
}

.editor-placeholder {
  position: absolute;
  top: 100px; 
  padding-left: 1em;
  left: 16px !important; 
  user-select: none;
  pointer-events: none;
}

.con-lex {
    position: relative;
    overflow: visible !important;
    z-index: 2000 !important; 
}


.con-lex > .editor-shell {
    margin: inherit;
}

.editor-shell {
    max-width: inherit !important;
}

/* Code editor text area */
.code-editor {
    width: 100%;
    height: 300px;
    font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 10px;
    background-color: #1e1e1e;
    color: #d4d4d4;
    border: 1px solid #333;
    border-radius: 4px;
    resize: vertical;
    overflow-y: auto;
    tab-size: 2;
  }
  .textBtn {
    align-content: flex-start;
  }


  .survey-response-drawer {
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
}

.survey-response-title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: bold;
  color: #1890ff;
}

.survey-response-card {
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 16px;
}

.survey-response-question {
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.survey-response-answer {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 8px;
  min-height: 60px;
  background-color: #f5f5f5;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  width: 100%;
}

.survey-response-submitted {
  font-style: italic;
  color: #888;
  text-align: right;
  margin-top: 8px;
}