.custom-table.masterstock-table .ant-table-thead>tr>th,
.custom-table.contractpricing-table .ant-table-thead>tr>th {
    font-size: 1.4rem !important;
}


.custom-table.masterstock-table .ant-table-tbody>tr>td,
.custom-table.contractpricing-table .ant-table-thead>tr>th {
    font-size: 1.2rem !important;
}

.contract-pricing-search {
    margin-bottom: 24px;
}

/* parcel labels */

.parcel-label-container {
    width: 381.73px;
    padding: 4px 8px;
    border: 0.1px solid #000;
    color: #000 !important
}

.parcel-label-container {
    font-size: 0.5rem;
}

.parcel-label-header {
    font-size: 10px;
    font-weight: 900;
}

.parcel-label-header-hidden {
    visibility: hidden;
}

.parcel-label-barcode {
    display: flex;
    justify-content:center;
    border: none !important;
}

.parcel-label-col {
    border: 0.1px solid #000;
    width: 100%;
    padding: 8px;
    font-size: 10px;
    font-weight: 900;
}

.label-barcode {
    width: 200px;
}

.label-barcode-container {
    display: flex;
    flex-direction: column;
}

.label-barcode-info {
    font-size: 8px;
}

.copy-btn{
    background: none !important;
}

.measurement-units {
    margin: 15px 0;
  }
  
  .measurement-units h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }
  
  .unit-labels {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .unit-labels span {
    font-size: 12px;
    color: #666;
    background-color: #f5f5f5;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }