#account-verification {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.account-verification-applogo {
    width: 250px;
}

.account-verification-header {
    margin-top: 16px;
    margin-bottom: 24px;
}

h2.account-verification-header {
    margin-top: 16px;
    margin-bottom: 24px;
}

.account-verification-input {
    width: 426px !important;
}