/*  */
.menu-icon {
    font-size: 2.0rem !important;
    align-self: center;
}

/*  */
.sidermain {
    background-color: #fff !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.sidermain-menu-link {
    font-size: 1.5rem;
    font-weight: 600;
}

.sidermain-logo-container {
    margin-top: 43px;
    margin-bottom: 90px;
    display: flex;
    justify-content: center;
}

.sidermain-logo {
    width: 160px;
}

.sidermain-logo-collapsed {
    width: 80%;
}

.sidermain-logout {
    position: absolute !important;
    bottom: 2% !important;
    padding-bottom: 16px;
    align-items: center;
    background-color: var(--color-primary-light) !important;
}

.sidermain-logouthover {
    background-color: white !important;
}

/* active menu */
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid var(--color-primary) !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    background: none !important;
}

.ant-menu-item-active * {
    color: var(--color-background) !important
}

.ant-menu-item-active .menu-icon svg {
    stroke: var(--color-background) !important
}

.ant-menu-item-selected * {
    color: var(--color-primary) !important
}

.ant-menu-item-selected .menu-icon svg {
    stroke: var(--color-primary) !important
}

.ant-menu-item-active.ant-menu-item-selected:hover {
    color: var(--color-primary) !important
}

.ant-menu-item-active.ant-menu-item-selected:hover .menu-icon svg {
    stroke: var(--color-primary) !important;
}

.menu-icon svg {
    stroke: #000;
}