.custom-files-upload .ant-upload-list {
    display: flex;
    overflow-y: scroll;
}

.custom-files-upload .ant-upload-select-picture-card,
.custom-files-upload .ant-upload-list-picture-card-container {
    display: block !important;
    border: 1.2028px dashed #181725 !important;
    border-radius: 6px !important;
    background: none !important;
    min-width: 104px !important;
}

.custom-files-upload  .ant-upload-list-picture-card-container {
    margin-right: 28px;
}

.custom-files-upload  .ant-upload-list-item-name {
    font-size: 1.0rem;
    color: #797979;
}

.pdf-icon-render {
    font-size: 16px;
}