.primary-btn {
    width: 100%;
    min-height: 64px;
    background-color: var(--color-primary) !important;
    border-radius: 6px !important;

    /* font-family: 'SF Pro Display'; */
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 1.3rem !important;
    text-transform: uppercase;
}

.small-btn {
    background: var(--color-primary) !important;
    border-radius: 4px !important;
    min-width: 92px;
    min-height: 40px;
    color: #F7FAFC !important;
    font-size: 1.63rem;
    font-weight: normal;
}

.text-btn {
    color: var(--color-primary) !important;
    font-size: 1.6rem;
}

.text-btnOK {
    color: var(--color-primary) !important;
    font-size: 1.6rem;
    background: none !important;
    border-color: var(--color-primary) !important;
}

.text-btnOK:hover {
    color: none !important;
    border-color: var(--color-primary) !important;

}

.ant-btn:hover,
.ant-btn:focus {
    color: var(--color-primary) !important;
    background: none !important;
    border-color: var(--color-primary) !important;
}

.dropdown-btn {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    background: #dadada !important;
    border-radius: 7px !important;
    min-height: 40px;
    color: var(--color-primary) !important;
    font-size: 1.1rem;
}

.dropdown-btn.ant-btn:hover {
    border-color: var(--color-primary) !important;
    background: #dadada !important;
}

/* //CSV Reader Button Styling */
/* Custom styles for CSVReader button */
.csv-reader-button input[type="file"] {
    position: relative;
    visibility: hidden;
}

.custom-csv-label {
    color: #333;
    font-size: 1.2rem;

}

.custom-csv-label:hover {
    color: #D8232A;

}

.uploadIcon {
    margin-right: 1rem;
    font-size: 1.2rem;

}

.csv-reader-button label {
    visibility: hidden;
}

.csvDiv {
    display: 'inline-flex';
    align-items: 'center';
    justify-content: 'center';
}