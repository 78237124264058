.ant-drawer-content-wrapper {
    border-radius: 20px 0px 0px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-drawer .ant-drawer-content {
    background-color: #F3F3F3;
    border-radius: 20px 0px 0px 20px;
    z-index: 10 !important;
}

.custom-drawer .ant-drawer-header {
    padding: 42px 54px;
    background-color: inherit;
    border-bottom: 1px solid var(--color-primary);
}

.custom-drawer .ant-drawer-title {
    font-weight: 600;
    font-size: 2.5rem;
    color: var(--color-primary);
}

.custom-form-submit {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;

}

.custom-form-submit .small-btn {
    width: 290px;
}

.custom-form label {
    font-weight: 600;
    font-size: 2.0rem !important;
}

.custom-form .ant-input,
.custom-form .ant-picker,
.custom-form .ant-input-number {
    background: none;
    border: none;
    border-bottom: 1.25px solid #ACACAC;
    border-radius: 0px !important;
    font-size: 1.8rem;
    padding-left: 0px;
    width: 100%;
}

.custom-form .ant-select-selector {
    background: none !important;
    border: none !important;
    border-bottom: 1.25px solid #ACACAC !important;
    border-radius: 0px !important;
}

/* Banner */
.ant-card.banner-card {
    border-radius: 8px;
    filter: drop-shadow(2px 4px 4px #ACACAC);
}

.banner-row {
    width: 100%;
    padding: 16px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.img-card-banner {
    width: 100%;
    display: block;
}

.banner-card-expired .img-card-banner {
    filter: grayscale();
}

/*
 * IMAGE MAP
 */

.image-map-drawer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#image-map {
    height: 95%;
    width: 100%;
}

.table-content-slider-image {
    max-width: 100px; 
    max-height: 200px;
    height: auto;
    display: block;
    margin: 0 auto; 
}
