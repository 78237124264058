.pageheader-options-categories {
    margin-right: 24px !important;
}

.long-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

img.product-image {
    display: block;
    max-width: 30px;
    max-height: 30px;
    width: auto;
    height: auto;
}

.skuPanelDrawer{
    z-index: 10 !important;
}
