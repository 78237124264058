#survey-root {
    background-color: var(--color-background);
    /* width: 100vw !important;*/
    min-height: 90vh !important;
    display: flex;
}


.question-type-list{
  display: flex;
  flex-direction: column;
  border-right: 1px solid #afafaf;
  margin-right: 40px;
  width: 20%;
  align-items: center;
}

.add-question-text{
  color: var(--color-primary);
  margin-top: 40px;
  margin-bottom: 25px;

}
.q-type-contain{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.question-type-box{
  width: 66px;
  height: 66px;
  border-radius: 10px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 ;
}


.type-name{
  font-size: 1rem;
}


.cb-question .ant-checkbox-group-item  {
  border-radius: 12px !important;
  background-color: #1D394862 !important;
  padding: 8px !important;
  color: #fff !important;
  margin: 4px;
}

.survey-droppable-area{
  display: flex;
  flex-direction: column;
}



.add-question-text{
  color: var(--color-primary);
  margin-top: 40px;
  margin-bottom: 25px;
  
}

.question-title{
  padding-left: 1em;
  max-width: 80%;
}

.question-type-box{
  width: 66px;
  height: 66px;
  border-radius: 10px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 ;
}

.cb-question .ant-checkbox-group-item  {
  border-radius: 12px !important;
  background-color: #1D394862 !important;
  padding: 8px !important;
  color: #fff !important;
  margin: 4px;
}

.q-type-contain{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.type-name{
  font-size: 1rem;
}

.survey-section{
  background-color: var(--color-background);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey-droppable-area{
  width: 95%;
  min-height: 70%;
  overflow-y: auto;
}

.survey-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.survey-select{
  width: 100% !important;
  margin: 50px 0px  !important;
    border: 0.5px solid black !important;
  background-color: transparent !important;
}

.survey-select  .ant-select-selector{
  background-color:gray solid !important;
  border: none !important;
}


.question{
  display: flex;
  flex-direction: column;
  border-bottom: gray solid 1px;
  padding: 30px 0px;
}

.question:hover{
  background-color: #fafafa;
}

.items-head{
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-opt{
  margin-right: 10px;
}

.add-item-btn{
  background-color: var(--color-primary) !important;
  color:  #fff !important;
  height: 25px !important;
  width: 25px !important;
  font-size: 0.8rem;
  border: none !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  border: none !important;
  border-radius: 50% !important;
  margin-left: 10px;
}

.question-top{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  color: var(--color-primary);
}

.element{
  margin-left: 1em !important;
}

.question-controls{
  color: #1E7D79;
}

.q-rate .ant-rate-star{
  font-size: 4rem !important;
  color: var(--color-primary) !important;
}

.edit-q{
  margin-right: 5px;
  color: var(--color-primary) !important;
  border: none !important;
}

.delete-q{
  color: var(--color-primary) !important;
  margin-right: 5px;
  border: none !important;
}

.ant-radio-inner::after{
  background-color: var(--color-primary) !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--color-primary) !important;
}

.ant-radio:hover .ant-radio-inner{
  border-color: var(--color-primary) !important;
}

.multi-choice{
  display: flex !important;
  flex-direction: column;
  margin-top: -20px !important;
}

.new-survey-btn{
  background-color: var(--color-primary) !important;
  color: #fff !important;
  border-color: #fff !important;
}

.new-survey-btn:hover{
  background-color: #1D3948c7 !important;
}

.welcome-closing-messsage{
  padding-left: 0.5em;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
}

.ant-checkbox-inner{
  border: 1px solid #d9d9d9 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}

.opening-msg{
  font-weight: bold;
  padding-left: 0.5em;
  font-size: 1.8rem;
  color: var(--color-primary);
}

.self-rev-cb{
  margin: 10px 0px !important;
}




.survey-root {
    display: flex;
    padding: 20px;
    background-color: #f0f2f5;
}

.question-type-list {
    width: 200px;
    margin-right: 20px;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.survey-section {
    flex: 1;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.question {
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
}

.question-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.question-title {
    font-weight: bold;
}

.question-element {
    margin-top: 10px;
}

.welcome-closing-messsage {
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.optional-fields-dropdown {
  margin-left: auto;
  width: 200px; /* Adjust as needed */
}
.survey{
  width: 90%;
}